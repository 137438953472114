<template>
  <div id="app" class="essai">
    <Popup />
    <div
      class="sm:block hidden"
      :class="`${background_class}`"
      :style="`background-image:url(${background_link})`"
    ></div>
    <HeaderElem />
    <router-view />
    <FooterElem />
  </div>
</template>

<script>
import Popup from "@/components/Gabarit1/Tools/Popup";
import HeaderElem from "@/components/Header";
import FooterElem from "@/components/Footer";

export default {
  name: "App",
  data: function () {
    return {
      datas: this.$root.configOp.datas,
      background_link: undefined,
      background_class: undefined,
    };
  },
  components: {
    Popup,
    HeaderElem,
    FooterElem,
  },
  mounted() {
    if (this.$root.configOp.datas) {
      if (this.datas.background_image_link) {
        // si background image
        this.background_link = this.datas.background_image_link;
        this.background_class = "background-body";
      } else if (this.datas.body_background_color) {
        // si background color
        document.body.style.backgroundColor = this.datas.body_background_color;
        this.background_class = "background-color";
      } else {
        // image floutée par defaut
        this.background_link = this.datas.banner_link || "/banner_mb.jpg";
        this.background_class = "background-image";
      }
    }
  },
};
</script>
